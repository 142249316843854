import { devInitData, devMode } from "../dev-mode"
import { _global_domain } from "../domain-address"

const UploadImage = async (files) => {
  const formData = new FormData()
  const initData = window.Telegram.WebApp.initData

  for (let i = 0; i < files.length; i++) {
    formData.append("files[]", files[i])
  }

  return await fetch(_global_domain + "/upload", {
    method: "POST",
    headers: {
      "X-Telegram-Init-Data": devMode ? devInitData : initData,
    },
    body: formData,
  }).then((response) => response.json())
}

export default UploadImage
