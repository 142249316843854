import { useDispatch, useSelector } from "react-redux"
import { MOVE_PROD_IMAGES } from "../../../reducers/types"
import EditProduct from "../../../queries/EditProduct"

const MoveImageControl = ({
    products,
    setSelectImage,
    select_image
}) => {

    const dispatch = useDispatch()
    const shops = useSelector(s => s.app.shops)

    const moveImageHandler = (dir) => {
        var prod_imgs = products[select_image.prod_index].imgs
        if (prod_imgs.length > 0) {

            let next_index = (dir === "left")
                ? select_image.img_index > 0
                    ? select_image.img_index - 1
                    : select_image.img_index
                : select_image.img_index < prod_imgs.length - 1
                    ? select_image.img_index + 1
                    : select_image.img_index

            let bubble = prod_imgs[select_image.img_index]
            prod_imgs[select_image.img_index] = prod_imgs[next_index]
            prod_imgs[next_index] = bubble
            setSelectImage({
                ...select_image,
                img_index: next_index
            })

            let new_imgs = prod_imgs.map(el => el.img)

            EditProduct("imgs", select_image.prod_id, new_imgs, shops.find(s => s.selected).id).then(response => 
                response &&
                dispatch({
                    type: MOVE_PROD_IMAGES, payload: {
                        id: select_image.prod_id,
                        imgs: prod_imgs
                    }
                })
            )
        }

    }

    return <div className="control-block">
        <div onClick={() => moveImageHandler("left")} className="move-left-img-toggle control-item">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" /></svg>
        </div>
        <div onClick={() => moveImageHandler("right")} className="move-right-img-toggle control-item">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" /></svg>
        </div>
    </div>
}

export default MoveImageControl