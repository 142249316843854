import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ADD_TO_BASKET } from "../../reducers/types";
import GetProduct from "../../queries/GetProduct";
import errorimg from "../../assets/img/errorimg.png"
import "./product-page.scss"

const ProductPage = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const basket = useSelector(s => s.app.basket)
    const [data_product, setDataProduct] = useState(false)
    const [inBasket, setInBasket] = useState(null)
    const navigate = useNavigate();

    const getDataProduct = useCallback(() => {
        return GetProduct(+params.id).then(response => setDataProduct({
            ...response.result.data,
            imgs: response.result.data.imgs.map((img, id) => {return{id, img}})
        }))
    }, [params.id])

    const addToBasket = () => {
        dispatch({type: ADD_TO_BASKET, payload: data_product.id})
    }


    useEffect(() => {getDataProduct()}, [getDataProduct])
    useEffect(() => {
        if(data_product){
            setInBasket(basket.some(b => b.id === data_product.id))
            localStorage.setItem("basket-dff-store", JSON.stringify(basket))
        }
    }, [data_product, basket])

    return !data_product 
    ? <div></div>
    : <div className="product-page-container">
        <div onClick={() => navigate(-1)} className="back-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"/></svg>
            <p>{data_product.name.substring(0, 30) + (data_product.name.length > 30 ? "..." : "")}</p>
        </div>
        <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            className="img-album">
            {data_product.imgs.map((d, i) => 
            <SwiperSlide key={d.id}>
                <img src={d?d.img:errorimg} alt=""/>
            </SwiperSlide>)}
        </Swiper>
        <div className="content-block">
            <p className="name-product">{data_product.name}</p>
            <p className="price-product">{data_product.price} ₽</p>
            {inBasket === null
            ? <></>
            : inBasket
                ? <Link to="/basket" className="to-basket-button in-basket">В корзину</Link>
                : <div onClick={addToBasket} className="to-basket-button">Добавить в корзину</div>}
        </div>
    </div>
}
export default ProductPage