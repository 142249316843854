import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { HIDE_MENU } from "../../reducers/types"
import GetContacts from "../../queries/GetContacts"
import "./active-menu.scss"

const ActiveMenu = () => {
    const [active, setActive] = useState(false)
    const [tg_link, setTgLink] = useState("")
    const catalog_block = useRef()
    const active_menu_global = useSelector(s => s.app.active_menu_global)
    const categories = useSelector(s => s.app.categories)
    const shops = useSelector(s => s.app.shops)
    const dispatch = useDispatch()

    const clMn = () => { // CLOSE MENU
        dispatch({ type: HIDE_MENU })
    }
    
      const getTgLink = useMemo(() => {
        if (!tg_link) return ''
        if (tg_link.startsWith('http')) return tg_link
        if (tg_link.startsWith('@')) return `https://t.me/${tg_link.slice(1)}`
        return `https://t.me/${tg_link}`
      }, [tg_link])

    useEffect(() => {
        console.log(shops)
        if(shops.length > 0){
            console.log(shops)
            GetContacts(
                1, 
                shops.find(s => s.selected).id
            ).then(response => {
                console.log(response)
                setTgLink(response.result.data.telegram)
            })

        }
    }, [shops])

    return <div className={"active-menu " + ((active_menu_global) ? "active" : "")}>
        <ul>
            <li className="catalog-but" onClick={() => setActive(!active)}>Каталог <svg className={active ? "active" : ""} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#444" d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg></li>
            <div ref={catalog_block} className={"catalog " + (active ? "active" : "")} style={{ height: active && catalog_block.current.scrollHeight }}>
                <ul>
                    {
                        categories.map(c => <li key={c.id}>
                            <NavLink onClick={clMn} to={"ctg/" + c.id}>{c.name}</NavLink>
                        </li>)
                    }
                </ul>
            </div>
            <li><NavLink onClick={clMn} to="contacts">Контакты</NavLink></li>
            <li><NavLink to={getTgLink}>Поддержка или чат с оператором</NavLink></li>
        </ul>
    </div>
}

export default ActiveMenu