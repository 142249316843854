import { devInitData, devMode } from "../dev-mode"
import { _global_domain } from "../domain-address"

const PayOrder = async (amount, id_user, id_order) => {
  const initData = window.Telegram.WebApp.initData
  return await fetch(_global_domain + "/pay_order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Telegram-Init-Data": devMode ? devInitData : initData,
    },
    body: JSON.stringify({
      amount,
      id_user,
      id_order
    }),
  }).then((response) => response.json())
}
export default PayOrder