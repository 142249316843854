import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { SAVE_NAME_PRODUCT } from "../../../reducers/types"
import { useDispatch, useSelector } from "react-redux"
import EditProduct from "../../../queries/EditProduct"

const ItemName = ({ name, id }) => {

    const [nameProduct, setNameProduct] = useState()
    const [activeField, setActiveField] = useState(false)
    const dispatch = useDispatch()
    const shops = useSelector(s => s.app.shops)

    const toggleActiveSave = () => {
        if(activeField){
            EditProduct("name", id, nameProduct, shops.find(s => s.selected).idt).then(response => response &&
                dispatch({type: SAVE_NAME_PRODUCT, payload: {id, name: nameProduct}})
            )
            setActiveField(false)
        }
        else 
            setActiveField(true)
    }

    useEffect(() => { setNameProduct(name) }, [name])

    return <div className="product-item-name">
        {activeField
            ? <TextField className="field" value={nameProduct} onChange={(e) => setNameProduct(e.target.value)} />
            : <h3>{name}</h3>
        }
        <svg onClick={toggleActiveSave} className="admin-but" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            {activeField
                ? <path fill="#444" d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z" />
                : <path fill="#444" d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
            }
        </svg>
    </div>
}

export default ItemName