import { ADD_ADMIN, ADD_ITEMS_PRODUCT_ORDER, ADD_NEW_IMAGE, ADD_NEW_PRODUCT, ADD_SHOPS, ADD_TO_BASKET, CHANGE_CLIENT_ADDRESS, CHANGE_CLIENT_ID, CHANGE_CLIENT_NAME, CHANGE_CLIENT_NUMBER, CHANGE_CLIENT_POINTS, CHANGE_CLIENT_USERNAME, CHANGE_NAME_CATEGORY, CHANGE_SHOP, CLOSE_SELECT_PRODUCTS, DEFAULT, DELETE_ADMIN, DELETE_CATEGORY, DELETE_CATEGORY_FROM_PRODUCT, DELETE_FROM_BASKET, DELETE_IMAGE, DELETE_ITEMS_PRODUCT_ORDER, DELETE_PRODUCT, HIDE_MENU, LOAD_BASKET, MINUS_IN_BASKET, MOVE_PROD_IMAGES, NEW_CATEGORY, OPEN_SELECT_PRODUCTS, PLUS_IN_BASKET, SAVE_AMOUNT_PRODUCT, SAVE_CATEGORY_FOR_PRODUCT, SAVE_NAME_PRODUCT, SAVE_PRICE_PRODUCT, SET_ADMINS, SET_CATEGORIES, SET_PRODUCTS, SET_SHOPS, TARGET_WS_INFO_POINTS_TARGET, TOGGLE_IN_HOME, TOGGLE_MENU, TOGGLE_SHOW_PRODUCT, TOGGLE_SHOW_SHOP, UPDATE_PRODUCT_LIST, UPDATE_QUANTITY_PRODUCT_ORDER } from "./types"

const initialState = {
    products: [
        /* {
             id: 13442115, 
             name: "Персиковые ранункулюсы «Венера»",
             price: 10900,
             imgs: [
                 {id: 1231, img: "https://lacybird.ru/upload/resize_cache/iblock/0fe/nkxeuu3566jzlbs19sxc5a2fogfx6qq1/383_383_2/11_1.jpg"},
                 {id: 1234, img: "https://259506.selcdn.ru/sites-static/site572278/3962620c-3f71-423a-84f3-4177348d999e/3962620c-3f71-423a-84f3-4177348d999e-2819056.jpeg"},
                 {id: 1235, img: "https://buket-ug.ru/upload/iblock/5bc/0hjqfgg1o4hj1edzppo9n9eppp8yjo9u/photo_2023-01-06_13-08-33.jpg"},
                 
             ],
             ctgs: [2, 1],
         },*/
    ],
    shops: [],
    basket: [],
    adminList: [],
    categories: [
    ],
    user_info: {
        id: -1,
        username: "",
        name: "",
        phone_number: "",
        address: "",
        points: 0
    },
    active_menu_global: false,
    ws_info_points_target: false,
    selectProductsDialogData: null,
    isUpdateOrders: false,
}

const noteReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEFAULT:
            return state
        case DELETE_FROM_BASKET:
            return {
                ...state,
                basket: [...state.basket.filter(b => +b.id !== +action.payload)]
            }
        case PLUS_IN_BASKET:
            return {
                ...state,
                basket: [...state.basket.map(b => +b.id === +action.payload ? {
                    ...b,
                    amount: b.amount + 1
                } : b)]
            }
        case MINUS_IN_BASKET:
            return {
                ...state,
                basket: [...state.basket.map(b => +b.id === +action.payload ? {
                    ...b,
                    amount: b.amount - 1
                } : b)]
            }
        case ADD_TO_BASKET:
            return {
                ...state,
                basket: [
                    ...state.basket,
                    {
                        id: action.payload,
                        amount: 1
                    }
                ]
            }
        case LOAD_BASKET:
            return {
                ...state,
                basket: action.payload
            }
        case HIDE_MENU:
            return {
                ...state,
                active_menu_global: false
            }
        case TOGGLE_MENU:
            return {
                ...state,
                active_menu_global: !state.active_menu_global
            }
        case CHANGE_CLIENT_ID:
            return {
                ...state,
                user_info: {
                    ...state.user_info,
                    id: action.payload
                }
            }
        case CHANGE_CLIENT_USERNAME:
            return {
                ...state,
                user_info: {
                    ...state.user_info,
                    username: action.payload
                }
            }
        case CHANGE_CLIENT_NAME:
            return {
                ...state,
                user_info: {
                    ...state.user_info,
                    name: action.payload
                }
            }
        case CHANGE_CLIENT_NUMBER:
            return {
                ...state,
                user_info: {
                    ...state.user_info,
                    phone_number: action.payload
                }
            }
        case CHANGE_CLIENT_ADDRESS:
            return {
                ...state,
                user_info: {
                    ...state.user_info,
                    address: action.payload
                }
            }
        case TOGGLE_IN_HOME:
            return {
                ...state,
                categories:
                    state.categories.map(c => c.id === action.payload ? {
                        ...c,
                        in_home: +!c.in_home
                    } : c)
            }
        case CHANGE_NAME_CATEGORY:
            return {
                ...state,
                categories:
                    state.categories.map(c => c.id === action.payload.id ? {
                        ...c,
                        name: action.payload.name
                    } : c)
            }
        case NEW_CATEGORY:
            return {
                ...state,
                categories: [
                    ...state.categories,
                    {
                        id: action.payload.id,
                        name: action.payload.name,
                        in_home: false
                    }
                ]
            }
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }

        case DELETE_CATEGORY:
            return {
                ...state,
                categories: state.categories.filter(c => c.id !== action.payload)
            }

        case ADD_NEW_IMAGE:
            return {
                ...state,
                products:
                    state.products.map(p => p.id === action.payload.id ?
                        {
                            ...p,
                            imgs: [
                                ...p.imgs,
                                ...action.payload.imgs.map((img, i) => {
                                    return {
                                        id: Date.now() + i,
                                        img
                                    }
                                })
                            ]
                        }
                        : p),
            }

        case MOVE_PROD_IMAGES:
            return {
                ...state,
                products:
                    state.products.map(p => p.id === action.payload.id ?
                        {
                            ...p,
                            imgs: action.payload.imgs
                        }
                        : p),
            }
        case DELETE_IMAGE:
            return {
                ...state,
                products:
                    state.products.map(p => p.id === action.payload.prod_id ?
                        {
                            ...p,
                            imgs: p.imgs.filter(img => img.id !== action.payload.img_id)
                        }
                        : p),
            }
        case DELETE_CATEGORY_FROM_PRODUCT:
            return {
                ...state,
                products:
                    state.products.map(p => p.id === action.payload.prod_id ?
                        {
                            ...p,
                            ctgs: p.ctgs.filter(ctg => ctg !== action.payload.ctg_id)
                        }
                        : p),
            }
        case SAVE_CATEGORY_FOR_PRODUCT:
            return {
                ...state,
                products:
                    state.products.map(p => p.id === action.payload.prod_id ?
                        {
                            ...p,
                            ctgs: [...p.ctgs, action.payload.ctg_id]
                        }
                        : p),
            }
        case SAVE_NAME_PRODUCT:
            return {
                ...state,
                products:
                    state.products.map(p => p.id === action.payload.id ?
                        {
                            ...p,
                            name: action.payload.name
                        }
                        : p),
            }
        case SAVE_PRICE_PRODUCT:
            return {
                ...state,
                products:
                    state.products.map(p => p.id === action.payload.id ?
                        {
                            ...p,
                            price: action.payload.price
                        }
                        : p),
            }
        case SAVE_AMOUNT_PRODUCT:
            return {
                ...state,
                products:
                    state.products.map(p => p.id === action.payload.id ?
                        {
                            ...p,
                            amount: action.payload.amount
                        }
                        : p),
            }
        case UPDATE_PRODUCT_LIST:
            return {
                ...state,
                products: [
                    ...state.products,
                    ...action.payload.filter(new_prod =>
                        !state.products.some(prev_prod => new_prod.id === prev_prod.id))
                ]
            }
        case SET_PRODUCTS:
            return {
                ...state,
                products: [
                    ...action.payload.map(p => {
                        return {
                            ...p,
                            imgs: p.imgs.map((img, id) => {
                                return { id, img }
                            })
                        }
                    }
                    )
                ]
            }
        case ADD_NEW_PRODUCT:
            return {
                ...state,
                products: [
                    ...state.products, 
                    action.payload
                ]
            }
        case DELETE_PRODUCT:
            return {
                ...state,
                products: [
                    ...state.products.filter(p => p.id !== action.payload)
                ]
            }
        case TARGET_WS_INFO_POINTS_TARGET:
            return {
                ...state,
                ws_info_points_target: !state.ws_info_points_target
            }
        case CHANGE_CLIENT_POINTS:
            return {
                ...state,
                user_info: {
                    ...state.user_info,
                    points: action.payload
                }
            }
        case CHANGE_SHOP:
            return {
                ...state,
                shops: state.shops.map(s => s.id === action.payload.id 
                    ? {...s, selected: true}
                    : {...s, selected: false})
            }
        case SET_SHOPS:
            return {
                ...state,
                shops: action.payload
            }
        case ADD_SHOPS:
            return {
                ...state,
                shops: [...state.shops, action.payload]
            }
        case ADD_ADMIN:
            return {
                ...state,
                adminList: [...state.adminList, action.payload]
            }
        case DELETE_ADMIN:
            return {
                ...state,
                adminList: state.adminList.filter(a => a.id !== action.payload)
            }
        case SET_ADMINS:
            return {
                ...state,
                adminList: action.payload
            }
        case TOGGLE_SHOW_PRODUCT:
            return {
                ...state,
                products: state.products.map(p => p.id === action.payload.id ?
                    {
                        ...p,
                        show: action.payload.show
                    }
                    : p),
            }
        case TOGGLE_SHOW_SHOP:
            return {
                ...state,
                shops: state.shops.map(s => +s.id === +action.payload.id ?
                    {
                        ...s,
                        show: action.payload.show
                    }
                    : s),
            }
        case OPEN_SELECT_PRODUCTS:
            return {
                ...state,
                selectProductsDialogData: {
                    id: action.payload.id,
                    items: action.payload.items,
                },
                isUpdateOrders: false,
            }
        case CLOSE_SELECT_PRODUCTS:
            return {
                ...state,
                selectProductsDialogData: null,
                isUpdateOrders: action.payload,
            }
        case UPDATE_QUANTITY_PRODUCT_ORDER:
            return {
                ...state,
                selectProductsDialogData: {
                    ...state.selectProductsDialogData,
                    items: state.selectProductsDialogData.items.map(item => {
                            if (item.id.toString() === action.payload.id.toString()) {
                                return {
                                    ...item,
                                    quantity: action.payload.quantity
                                }
                            }
                            return item
                        })
                },
            }
        case DELETE_ITEMS_PRODUCT_ORDER:
            return {
                ...state,
                selectProductsDialogData: {
                    ...state.selectProductsDialogData,
                    items: state.selectProductsDialogData.items
                    .filter(item => item.id.toString() !== action.payload.toString())
                },
            }
        case ADD_ITEMS_PRODUCT_ORDER:
            return {
                ...state,
                selectProductsDialogData: {
                    ...state.selectProductsDialogData,
                    items: [...state.selectProductsDialogData.items, {id: action.payload, quantity: 1}]
                },
            }

        default:
            return state
    }
}

export default noteReducer