import { useCallback, useState } from "react"
import Loading from "../../../fixed-elements/Loading"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import { ListItem, ListItemIcon, ListItemText } from "@mui/material"
import CompleteOrder from "../../../../queries/CompleteOrder"

function OrderCard({ order, orders, setOrders }) {
  const option_select = [
    { id: 1, label: "с 08:00 до 10:00" },
    { id: 2, label: "с 10:00 до 12:00" },
    { id: 3, label: "с 12:00 до 14:00" },
    { id: 4, label: "с 14:00 до 16:00" },
    { id: 5, label: "с 16:00 до 18:00" },
    { id: 6, label: "с 18:00 до 20:00" },
    { id: 7, label: "с 20:00 до 22:00" },
  ]

  const shops = useSelector(state => state.app.shops)  
  const [onBlocked, setOnBlocked] = useState([])

  const checkBlockedId = (id) => onBlocked.some(c_id => c_id === id)
  const getPriceOrder = useCallback((o) => o.id_product.reduce((acc, cur) => acc + (cur.price * cur.amount) , 0), [])
  const completeOrderHandler = async (id) => {
    if (!checkBlockedId(id)) {
      setOnBlocked([...onBlocked, id])
      await CompleteOrder(id, shops.find((s) => s.selected).id).then((response) => {
        response.result &&
          setOrders([
            ...orders.map((o) =>
              o.id === id
                ? {
                    ...o,
                    completed: response.result,
                  }
                : o
            ),
          ])

        setOnBlocked([...onBlocked.filter((c_id) => c_id !== id)])
      })
    }
  }

  return (
    <div className={"order-item " + (order.completed ? "close-item" : "")}>
      {order.completed ? <h5 className="close-tag">Выполнен</h5> : ""}
      <div className="heading-item">
        <h2>Заказ №{order.id}</h2>
        <h4 className={order.delivery === 2 ? "s1" : "s2"}>{order.delivery === 2 ? "Доставка" : "Самовывоз"}</h4>
      </div>
      <p>
        <span className="key">Заказ создан: </span>
        {dayjs(order.date_create).format("DD.MM.YYYY HH:mm")}
      </p>
      <p className={order.paid ? "s2" : "s1"}>
        <span className="key">Статус заказа: </span>
        {order.paid === 1 ? "Оплачен" : "Не оплачен"}
      </p>
      <p>
        <span className="key">Имя покупателя: </span>
        {order.name}
      </p>
      <p>
        <span className="key">Номер покупателя: </span>
        {order.phone_number}
      </p>
      <p>
        <span className="key">Дата получения: </span>
        {order.date_receipt}
      </p>
      <p>
        <span className="key">Время получения: </span>
        {option_select.filter((sel) => +sel.id === +order.time_receipt)[0].label}
      </p>
      {order.delivery === 2 ? (
        <p>
          <span className="key">Адрес: </span>
          {order.address_delivery}
        </p>
      ) : (
        ""
      )}
      {order.another_person ? (
        <div className="another-person-block">
          <h4>Получит другой человек:</h4>
          <p>
            <span className="key">Имя получателя: </span>
            {order.name_another_person}
          </p>
          <p>
            <span className="key">Номер получателя: </span>
            {order.phone_number_another_person}
          </p>
        </div>
      ) : (
        ""
      )}
      {order.postcard ? (
        <div className="postcard-block">
          <h4>Заказ с открыткой</h4>
          <p>
            <span className="key">Текст открытки:</span>
          </p>
          <p>{order.postcard_text}</p>
        </div>
      ) : (
        ""
      )}
      <div className="product-list">
        <h3>Товары:</h3>
        {order.id_product.map((p) => (
          <ListItem key={p.id} className="product-item">
            <ListItemIcon>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path fill="#444" d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z" />
              </svg>
            </ListItemIcon>
            <ListItemText primary={`№${p.id} - ${p.name} - ${p.price}₽ - ${p.amount}шт`} />
          </ListItem>
        ))}
        <p>
          <span className="key">Оплата баллами:</span> <b>{order.points} баллов</b>
        </p>
        <p>
          <span className="key">Итог:</span>{" "}
          <b>
            {getPriceOrder(order)}₽ - {order.points} баллов ({getPriceOrder(order) - order.points}₽)
          </b>
        </p>
      </div>

      {order.anonymous ? <h5>Анонимно</h5> : ""}
      {!order.completed ? (
        <div onClick={() => completeOrderHandler(order.id)} className={"order-completed-but " + (checkBlockedId(order.id) ? "loading-but" : "")}>
          {checkBlockedId(order.id) ? <Loading fill="#fff" /> : "Заказ выполнен"}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default OrderCard
