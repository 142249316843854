import { useEffect, useState } from "react"
import EditProduct from "../../../queries/EditProduct"
import { SAVE_AMOUNT_PRODUCT } from "../../../reducers/types"
import { useDispatch, useSelector } from "react-redux"

let timeout = null

function ItemAmount ({_amount = 0, id}){
    const [amount, setAmount] = useState(0)
    const dispatch = useDispatch()
    const shops = useSelector(s => s.app.shops)

    function amountActionHandler(value){
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            EditProduct("amount", id, value, shops.find(s => s.selected).id).then(resp => {
                if(resp){
                    dispatch({
                        type: SAVE_AMOUNT_PRODUCT,
                        payload: {
                            id,
                            amount: value
                        }
                    })
                }
            })
        }, 2000)
    }

    function plusAmount(){
        let value = amount + 1
        setAmount(value)
        amountActionHandler(value)
    }
    function minusAmount(){
        let value = amount - 1
        setAmount(value)
        amountActionHandler(value)
    }
    function changeAmount(value){
        setAmount(value)
        amountActionHandler(value)
    }

    useEffect(() => { setAmount(_amount) }, [_amount])

    return <div className="product-item-amount">
            <input className="field-number" type="number" value={amount} onChange={(e) => changeAmount(e.target.value)} />
            <span>шт.</span>
            <div className="buttons">
                <button onClick={plusAmount}>+</button>
                <button onClick={minusAmount}>-</button>
            </div>
        </div>

}

export default ItemAmount