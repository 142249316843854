import { devInitData, devMode } from "../dev-mode"
import { _global_domain } from "../domain-address"

const CheckAdmin = async (id_admin) => {
  const initData = window.Telegram.WebApp.initData
  return await fetch(_global_domain + "/is_admin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Telegram-Init-Data": devMode ? devInitData : initData,
    },
    body: JSON.stringify({ id_admin }),
  }).then((response) => response.json())
}
export default CheckAdmin
