import { devInitData, devMode } from "../dev-mode"
import { _global_domain } from "../domain-address"

const GetAllProducts = async (limit, offset, shop_id, show) => {
  const initData = window.Telegram.WebApp.initData
  return await fetch(_global_domain + "/get_products", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Telegram-Init-Data": devMode ? devInitData : initData,
    },
    body: JSON.stringify({ limit, offset, shop_id, show, ids: [] }),
  }).then((response) => response.json())
}

export default GetAllProducts
