import { devInitData, devMode } from "../dev-mode";
import { _global_domain } from "../domain-address"

const AddNewShop = async(nameShop) => {
    const initData = window.Telegram.WebApp.initData;
    return await fetch(_global_domain + "/add_shop", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'X-Telegram-Init-Data': devMode ? devInitData : initData
        },
        body: JSON.stringify(
            {
                name: nameShop,
                show: 1
            }
        )
    })
        .then(response => response.json())

}
export default AddNewShop