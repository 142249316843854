import { ListItem, ListItemIcon, ListItemText } from "@mui/material"
import dayjs from "dayjs"
import { useDispatch } from "react-redux"
import UpdateEdaStatus from "../../../../queries/UpdateEdaStatus"
import { OPEN_SELECT_PRODUCTS } from "../../../../reducers/types"

function OrderYandexCard({ order, orders, setOrders }) {

  const dispatch = useDispatch()

  function checkStatus (status) {
    return order.status === status
  }
  function getButtonText () {
    switch (order.status) {
      case "NEW":
        return "Принять заказ"
      case "ACCEPTED_BY_RESTAURANT":
        return "В сборку"
      case "COOKING":
        return "Заказ собран"
      default:
        return null
    }
  }
  function getNameStatus () {
    switch (order.status) {
      case "NEW":
        return "Новый заказ!"
      case "ACCEPTED_BY_RESTAURANT":
        return "Заказ подтвержден"
      case "COOKING":
        return "Началась сборка заказа"
      case "READY":
        return "Заказ собран"
      case "TAKEN_BY_COURIER":
        return "Заказ забрал курьер"
      case "DELIVERED":
        return "Заказ доставлен"
      case "CANCELLED":
        return "Отменен"
      default:
        return "Неизвестно"
    }
  }
  function getStatusColor () {
    switch (order.status) {
      case "NEW":
        return "#dd7800"
      case "ACCEPTED_BY_RESTAURANT":
        return "#512de6"
      case "COOKING":
        return "#512de6"
      case "READY":
        return "#512de6"
      case "TAKEN_BY_COURIER":
        return "#512de6"
      case "DELIVERED":
        return "#4CAF50"
      case "CANCELLED":
        return "#944"
      default:
        return "#000"
    }
  }

  function updateStatusHandler () {
    let newStatus = ""
    switch (order.status) {
        case "NEW":
          newStatus = "ACCEPTED_BY_RESTAURANT"
          break
        case "ACCEPTED_BY_RESTAURANT":
          newStatus = "COOKING"
          break
        case "COOKING":
          newStatus = "READY"
          break
        default:
          newStatus = null
          
      }
      if (newStatus !== null) UpdateEdaStatus(order.id, newStatus, order.shop_id).then(response => {
        if (response.result) {
          setOrders(orders.map(item => {
            if (item.id === order.id) {
              item.status = newStatus
            }
            return item
          }))
        }
      })
  }

  function replaceProductHundler(){
    dispatch({type: OPEN_SELECT_PRODUCTS, payload: {id: order.id, items: order.items.map(item => {
            return {id: item.id, quantity: item.quantity}
        })
    }})
  }

  function cancelOrderHandler () {
    UpdateEdaStatus(order.id, "CANCELLED", order.shop_id).then(response => {
        if (response.result) {
          setOrders(orders.map(item => {
            if (item.id === order.id) {
              item.status = "CANCELLED"
            }
            return item
          }))
        }
      })
  }

  return (
    <div className={"order-item yandex-order " + ((checkStatus("CANCELLED") || checkStatus("DELIVERED")) ? "close-item" : "")}>
        <h2>Яндекс-заказ №{order.id}</h2>
        <p>
          <span className="key">Заказ создан: </span>
          {dayjs(order.created_at).format("DD.MM.YYYY HH:mm")}
        </p>
        <p className="status-yandex-order">
            <span className="key">Статус заказа: </span>
            <span style={{color: getStatusColor()}} className={`status-value`}>{getNameStatus()}</span>
        </p>
        <p>
            <span className="key">Имя покупателя: </span>
            {order.client_name}
        </p>
        <p>
            <span className="key">Номер покупателя: </span>
            {order.phone_number}
        </p>
        <p>
            <span className="key">Код курьера: </span>
            {order.courier_code}
        </p>
        <p>
            <span className="key">Адрес доставки: </span>
            {order.delivery_address}
        </p>
        
      <div className="product-list">
        <div className="product-list-header">
            <h3>Товары:</h3>
            <button className="product-item-button" onClick={replaceProductHundler}>Заменить товары</button>
        </div>
        {order.items.map((p) => (
          <ListItem key={p.id} className="product-item">
            <ListItemIcon>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path fill="#444" d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z" />
              </svg>
            </ListItemIcon>
            <ListItemText primary={`№${p.id} - ${p.name} - ${p.price}₽ - ${p.quantity}шт`} />
          </ListItem>
        ))}
        <b>Итоговая цена: {order.total_cost} ₽</b>
      </div>
      {(getButtonText() !== null) && (
        <div onClick={updateStatusHandler} className={"order-status-control-but "}>{getButtonText()}</div>
      )}
      {!checkStatus("CANCELLED") ? (
        <div onClick={cancelOrderHandler} className={"order-cancel-but "}>
          Отменить заказ
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default OrderYandexCard
