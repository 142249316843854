import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CHANGE_NAME_CATEGORY, DELETE_CATEGORY, NEW_CATEGORY, TOGGLE_IN_HOME } from "../../../reducers/types"
import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import NewCategory from "../../../queries/NewCategory"
import DeleteCategory from "../../../queries/DeleteCategory"
import EditCategory from "../../../queries/EditCategory"
import CheckAdmin from "../../../queries/CheckAdmin"
import Loading from "../../fixed-elements/Loading"
import { devMode, devUserId } from "../../../dev-mode"

const CategoriesPage = () => {
    const navigate = useNavigate()
    const categories = useSelector(s => s.app.categories)
    const shops = useSelector(s => s.app.shops)
    const dispatch = useDispatch()
    const [edit_id, setEditId] = useState(-1)
    const [textStorage, setTextStorage] = useState("")
    const [onAddItem, setOnAddItem] = useState(false)
    const [nameNewCategory, setNameNewCategory] = useState("")
    const [isUserAdmin, setIsUserAdmin] = useState(false)

    const toggleInHome = (c) => 
        EditCategory('in_home', c.id, +!c.in_home, shops.find(s => s.selected).id).then(response => {
            response.result &&
                dispatch({ type: TOGGLE_IN_HOME, payload: c.id })
        })

    const openTextField = (id, name) => {
        setEditId(id)
        setTextStorage(name)
    }

    const hideTextField = (id, name) => 
        EditCategory('name', id, name, shops.find(s => s.selected).id).then(response => {
            if (response.result) {
                dispatch({ type: CHANGE_NAME_CATEGORY, payload: { id, name } })
                setEditId(-1)
                setTextStorage("")
            }
        })
    

    const cancelAddItem = () => {
        setOnAddItem(false);
        setNameNewCategory("")
    }

    const enterAddItem = async () => {

        await NewCategory(nameNewCategory, shops.find(s => s.selected).id).then(response => { dispatch({ type: NEW_CATEGORY, payload: { id: response.result.id, name: nameNewCategory } }) })
        cancelAddItem()
    }

    const deleteItem = (id) => {
        DeleteCategory(id, shops.find(s => s.selected).id).then(response => {
            response &&
                dispatch({ type: DELETE_CATEGORY, payload: id })
        }
        )
    }

    useEffect(() => {
        let tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe.user || devMode){
            const propId = devMode ? devUserId : tg.initDataUnsafe.user.id
            CheckAdmin(propId).then(response => {
                setIsUserAdmin(response.result)
                !response.result && navigate("/")
            })
        }
    }, [navigate])

    return isUserAdmin
    ? <div className="categories-page-container admin-block-container">
        <div onClick={() => navigate(-1)} className="back-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" /></svg>
            <p>Назад</p>
        </div>
        <h1 className="heading">Категории</h1>
        <div className="categories-list">
            {
                categories.map(c => <div className="categories-item" key={c.id}>
                    {edit_id === c.id
                        ? <TextField className="item-name" value={textStorage} onChange={(e) => setTextStorage(e.target.value)} />
                        : <p className="item-name">{c.name}</p>
                    }
                    <div className="item-control">

                        {c.in_home
                            ? <svg onClick={() => toggleInHome(c)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#0DBC69" d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" /></svg>
                            : <svg onClick={() => toggleInHome(c)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#E34C4C" d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" /></svg>
                        }
                        {edit_id === c.id
                            ? <svg onClick={() => hideTextField(c.id, textStorage)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z" /></svg>
                            : <svg onClick={() => openTextField(c.id, c.name)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                        }
                        {<svg onClick={() => deleteItem(c.id)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>}
                    </div>
                </div>)
            }
            {onAddItem
                ? <div className="add-block">
                    <TextField className="name-ctg" label="Новая категория" value={nameNewCategory} onChange={(e) => setNameNewCategory(e.target.value)} />
                    <svg onClick={enterAddItem} className="add-ctg" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                    <svg onClick={cancelAddItem} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#444" d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                </div>
                : <svg onClick={() => setOnAddItem(true)} className="categories-control" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
            }
        </div>
    </div>
    : <Loading />
}

export default CategoriesPage