import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import LoadImagesContainer from "./LoadImagesContainer"
import CategoryItem from "./CategoryItem"
import SelectionBlock from "./SelectionBlock"
import NewProduct from "../../../queries/NewProduct"
import { useDispatch, useSelector } from "react-redux"
import { ADD_NEW_PRODUCT } from "../../../reducers/types"
import Loading from "../../fixed-elements/Loading"
import CheckAdmin from "../../../queries/CheckAdmin"
import { devMode, devUserId } from "../../../dev-mode"

const AddProductPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const shops = useSelector((s) => s.app.shops)

  const [nameProduct, setNameProduct] = useState("")
  const [priceProduct, setPriceProduct] = useState(0)
  const [amountProduct, setAmountProduct] = useState(0)
  const [images_links, setImagesLinks] = useState([])
  const [ctgsProduct, setCtgsProduct] = useState([])
  const [isUserAdmin, setIsUserAdmin] = useState(false)

  const addProductHandler = () => {
    const currentShop = shops.find((s) => s.selected)
    let new_ctgs = ctgsProduct.map((c) => c.id)

    NewProduct(nameProduct, priceProduct, images_links, new_ctgs, amountProduct, currentShop.id).then((response) => {
      dispatch({
        type: ADD_NEW_PRODUCT,
        payload: {
          id: response.result.id,
          name: nameProduct,
          price: priceProduct,
          imgs: images_links.map((img, id) => {
            return { id, img }
          }),
          ctgs: new_ctgs,
        },
      })
    })
    navigate(-1)
  }

    function plusAmount() {
        let value = amountProduct + 1
        setAmountProduct(value)
    }
    function minusAmount() {
        let value = amountProduct - 1
        setAmountProduct(value)
    }
    function changeAmount(value) {
        setAmountProduct(value)
    }

  useEffect(() => {
    let tg = window.Telegram.WebApp
    if (tg.initDataUnsafe.user || devMode) {
      const propId = devMode ? devUserId : tg.initDataUnsafe.user.id
      CheckAdmin(propId).then((response) => {
        setIsUserAdmin(response.result)
        !response.result && navigate("/")
      })
    }
  }, [navigate])

  return isUserAdmin ? (
    <div className="add-product-page-container admin-block-container">
      <div onClick={() => navigate(-1)} className="back-block">
        <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30">
          <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
        </svg>
        <p>Назад</p>
      </div>
      <h1 className="heading">Добавление товара</h1>
      <div className="block-inputs">
        <TextField className="input" value={nameProduct} onChange={(e) => setNameProduct(e.target.value)} label="Название" />
        <TextField className="input" value={priceProduct} onChange={(e) => setPriceProduct(e.target.value)} label="Цена" />
        <LoadImagesContainer images_links={images_links} setImagesLinks={setImagesLinks} />
        <h2 className="categories-block-heading">Категории</h2>
        <div className="categories-block">
          {ctgsProduct.map((ctg) => (
            <CategoryItem key={ctg.id} ctg={ctg} setCtgsProduct={setCtgsProduct} ctgsProduct={ctgsProduct} />
          ))}
          <SelectionBlock setCtgsProduct={setCtgsProduct} ctgsProduct={ctgsProduct} />
        </div>
        <h2 className="categories-block-heading">Количество</h2>
        <div className="product-item-amount">
          <input className="field-number" type="number" value={amountProduct} onChange={changeAmount} />
          <span>шт.</span>
          <div className="buttons">
            <button onClick={plusAmount}>+</button>
            <button onClick={minusAmount}>-</button>
          </div>
        </div>
        <div className="add-product-but" onClick={addProductHandler}>
          Добавить товар
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  )
}

export default AddProductPage
