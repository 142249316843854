import { devInitData, devMode } from "../dev-mode"
import { _global_domain } from "../domain-address"

const GetOrders = async (shop_id, limit, offset) => {
  const initData = window.Telegram.WebApp.initData
  return await fetch(_global_domain + "/get_orders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Telegram-Init-Data": devMode ? devInitData : initData,
    },
    body: JSON.stringify({ shop_id, limit, offset }),
  }).then((response) => response.json())
}

export default GetOrders
