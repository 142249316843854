import { devInitData, devMode } from "../dev-mode";
import { _global_domain } from "../domain-address"

const AddAdmin = async(id_admin, name, shop_id) => {
    const initData = window.Telegram.WebApp.initData;
    return await fetch(_global_domain + "/add_admin", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'X-Telegram-Init-Data': devMode ? devInitData : initData
        },
        body: JSON.stringify(
            {
                id_admin,
                name,
                shop_id
            }
        )
    })
        .then(response => response.json())

}
export default AddAdmin