import { devInitData, devMode } from "../dev-mode";
import { _global_domain } from "../domain-address"

const AddContact = async(adress, contact_phone, telegram, shop_id) => {
    const initData = window.Telegram.WebApp.initData;
    return await fetch(_global_domain + "/add_contact", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'X-Telegram-Init-Data': devMode ? devInitData : initData
        },
        body: JSON.stringify(
            {
                adress,
                contact_phone,
                telegram,
                shop_id
            }
        )
    })
        .then(response => response.json())

}
export default AddContact