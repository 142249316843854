import { useDispatch, useSelector } from "react-redux"
import { NavLink, useNavigate } from "react-router-dom"
import { ADD_NEW_IMAGE, DELETE_PRODUCT, SET_PRODUCTS, TOGGLE_SHOW_PRODUCT } from "../../../reducers/types"
import { useEffect, useState } from "react"
import ItemName from "./ItemName"
import ItemPrice from "./ItemPrice"
import ItemAmount from "./ItemAmount"
import AlbumItem from "./AlbumItem"
import ImageControlBlock from "./ImageControlBlock"
import MoveImageControl from "./MoveImageContol"
import CategoryItem from "./CategoryItem"
import SelectionBlock from "./SelectionBlock"
import UploadImage from "../../../queries/UploadImage"
import GetAllProducts from "../../../queries/GetAllProducts"
import DeleteProduct from "../../../queries/DeleteProduct"
import EditProduct from "../../../queries/EditProduct"
import Loading from "../../fixed-elements/Loading"
import CheckAdmin from "../../../queries/CheckAdmin"
import { devMode, devUserId } from "../../../dev-mode"

const ProductsPage = () => {
    const navigate = useNavigate()
    const products = useSelector(s => s.app.products)
    const shops = useSelector(s => s.app.shops)
    const dispatch = useDispatch()
    const [select_image, setSelectImage] = useState({ prod_id: -1, img_id: -1, img_index: -1, prod_index: -1 })
    const [onMoveId, setOnMoveId] = useState({ prod_id: -1, img_id: -1 })
    const [isUserAdmin, setIsUserAdmin] = useState(false)

    const uploadImageHandler = async (files, prod) => {
        await UploadImage(files).then(resp_imgs => {
            console.log(prod)
            let new_imgs = [
                ...prod.imgs.map(el => el.img),
                ...resp_imgs.file_urls
            ]
            EditProduct("imgs", prod.id, new_imgs, shops.find(s => s.selected).id).then(resp_edit => {
                resp_edit &&
                dispatch({
                    type: ADD_NEW_IMAGE, payload: {
                        id: prod.id,
                        imgs: resp_imgs.file_urls // Массив из картинок
                    }
                })
            })
        });

    }

    const deleteProductHandler = (id) => {
        DeleteProduct(id, shops.find(s => s.selected).id).then(response =>{
            response.result &&
            dispatch({ type: DELETE_PRODUCT, payload: id })
        })
    }

    const toggleShowHandler = (id, isShow) => {
        EditProduct("show", id, +isShow, shops.find(s => s.selected).id).then(response =>{
            response.result &&
            dispatch({ type: TOGGLE_SHOW_PRODUCT, payload: {id, show: isShow} })
        })
    }

    const resetSelectImage = () => setSelectImage({ prod_id: -1, img_id: -1, img_index: -1, prod_index: -1 })
    const resetMoveImage = () => setOnMoveId({ prod_id: -1, img_id: -1 })

    useEffect(() => {
        if(shops.length !== 0){
            let tg = window.Telegram.WebApp;
            if (tg.initDataUnsafe.user || devMode) {
                const propId = devMode ? devUserId : tg.initDataUnsafe.user.id
                CheckAdmin(propId).then(response => {
                    setIsUserAdmin(response.result)
                    !response.result && navigate("/")
                })
            }

            GetAllProducts(2000, 0, shops.find(s => s.selected).id, false).then(responseNoShow => {
                GetAllProducts(2000, 0, shops.find(s => s.selected).id, true).then(responseShow => {
                    let payload = responseNoShow.result ? responseNoShow.result.data : []
                    if(responseShow.result) payload = payload.concat(responseShow.result.data)
                    dispatch({ type: SET_PRODUCTS, payload })
                    console.log(payload)
                })
            })
                
        }
    }, [dispatch, navigate, shops])

    return isUserAdmin ? <div className="products-page-container admin-block-container">
        <div onClick={() => navigate(-1)} className="back-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" /></svg>
            <p>Назад</p>
        </div>
        <div className="products-control">
            <h1 className="heading">Товары</h1>
            <NavLink to="new" className="add_but">Добавить товар</NavLink>
        </div>
        <div className="products-list">
            {products &&
                products.map((p, prod_index) => <div className="product-item" key={p.id}>
                    <ItemName name={p.name} id={p.id} />
                    <ItemPrice price={p.price} id={p.id} />
                    <ItemAmount _amount={p.amount} id={p.id} />
                    <div className="album">
                        {
                            p.imgs.map((el, img_index) => <AlbumItem
                                key={img_index}
                                setSelectImage={setSelectImage}
                                resetSelectImage={resetSelectImage}
                                resetMoveImage={resetMoveImage}
                                img={el.img}
                                prod_id={p.id}
                                img_id={el.id}
                                img_index={img_index}
                                prod_index={prod_index}
                                select_image={select_image}
                            />)
                        }
                    </div>
                    <div className="img-control">
                        <div className="control-block">
                            <div className="file-upload-but control-item">
                                <input name={"file" + p.id} id={"file" + p.id} type="file" placeholder="Да" multiple
                                    onChange={(e) => uploadImageHandler(e.target.files, p)} />
                                <label htmlFor={"file" + p.id} >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
                                </label>
                            </div>
                            {select_image.prod_id === p.id && <ImageControlBlock
                                select_image={select_image}
                                resetMoveImage={resetMoveImage}
                                resetSelectImage={resetSelectImage}
                                onMoveId={onMoveId}
                                prod={p}
                                setOnMoveId={setOnMoveId}
                            />}
                        </div>
                        {onMoveId.prod_id === p.id && <MoveImageControl
                            products={products}
                            setSelectImage={setSelectImage}
                            select_image={select_image}
                        />}
                    </div>
                    <p className="categories-block-heading">Категории</p>
                    <div className="categories-block">
                        {p.ctgs.map(id => <CategoryItem
                            key={id}
                            prod={p}
                            ctg_id={id}
                        />)}
                        <SelectionBlock
                            prod={p}
                            ctgs={p.ctgs}
                        />
                    </div>
                    <div className="product-item-control">
                        <div onClick={() => deleteProductHandler(p.id)} className="delete-but">Удалить</div>
                        <div onClick={() => toggleShowHandler(p.id, !p.show)} className="show-product-button">
                        {p.show 
                            ? <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="35px" fill="#27cc3a"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="35px" fill="#cc2727"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
                        }
                        </div>
                    </div>
                </div>)
            }
        </div>
    </div>
    : <Loading />
}

export default ProductsPage