import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import GetProducts from "../../../../queries/GetProducts"
import { Checkbox } from "@mui/material"
import Loading from "../../../fixed-elements/Loading"
import { ADD_ITEMS_PRODUCT_ORDER, CLOSE_SELECT_PRODUCTS, DELETE_ITEMS_PRODUCT_ORDER, OPEN_SELECT_PRODUCTS, UPDATE_QUANTITY_PRODUCT_ORDER } from "../../../../reducers/types"
import UpdateItemsInOrder from "../../../../queries/UpdateItemsInOrder"

function DialogSelectProducts() {

    const dispatch = useDispatch()
    const selectProductsDialogData = useSelector(s => s.app.selectProductsDialogData)
    const shops = useSelector(s => s.app.shops)
    const [products, setProducts] = useState([])

    const loadData = useCallback(async () => {
        const responseProducts = await GetProducts([], 5000, 0, [], shops.find(s => s.selected).id)
        const productsIds = responseProducts.result.data.map(item => item.id)
        const new_items = selectProductsDialogData.items.filter(item => productsIds.includes(item.id))

        new_items.length !== selectProductsDialogData.items.length &&
        dispatch({type: OPEN_SELECT_PRODUCTS, payload: {
            id: selectProductsDialogData.id, 
            items: new_items
        }})

        setProducts(responseProducts.result.data)
    }, [shops, dispatch, selectProductsDialogData])

    const getPrice = useCallback((id) => {
        return products.find(p => p.id.toString() === id.toString()).price
    }, [products])
    const getName = useCallback((id) => {
        return products.find(p => p.id.toString() === id.toString()).name
    }, [products])

    const getQuantity = useCallback((product) => {
        return selectProductsDialogData.items.find(p => p.id.toString() === product.id.toString()).quantity
    }, [selectProductsDialogData.items])

    const isInOrder = useCallback((product) => {
        return selectProductsDialogData.items.some(p => p.id.toString() === product.id.toString())
    }, [selectProductsDialogData.items])

    function updateQuantityHandler(id, quantity){
        dispatch({type: UPDATE_QUANTITY_PRODUCT_ORDER, payload: {id, quantity}})
    }
    function updateItemsHandler(id, checked){
        if(checked){
            dispatch({type: ADD_ITEMS_PRODUCT_ORDER, payload: id})
        }else{
            dispatch({type: DELETE_ITEMS_PRODUCT_ORDER, payload: id})
        }
    }
    function saveReplacementHandler(){
        const items = selectProductsDialogData.items.map(p => {
            return {
                id: p.id,
                price: getPrice(p.id),
                name: getName(p.id),
                quantity: p.quantity,
                modifications: [],
                promos: []
            }
        })
        UpdateItemsInOrder(selectProductsDialogData.id, shops.find(s => s.selected).id, items)
        .then((response) => {
            console.log(response)
            dispatch({type: CLOSE_SELECT_PRODUCTS, payload: true})
        })
    }

    useEffect(() => {
        loadData()
    }, [loadData])

    useEffect(() => {console.log(selectProductsDialogData)}, [selectProductsDialogData])

  return (
    <div className="dialog-select-product">
        <div onClick={() => dispatch({type: CLOSE_SELECT_PRODUCTS, payload: false})} className="dialog-select-product-cross">
            <span className="dialog-select-product-line"></span>
            <span className="dialog-select-product-line"></span>
        </div>
        <h2>Замена товара</h2>
        <div className="dialog-select-product-content">
            <ul className="dialog-select-product-content-list">
                {products.length > 0
                ? products
                .sort((a, b)=> isInOrder(b) - isInOrder(a))
                .map(p => <li key={p.id} className="dialog-select-product-content-item">
                    <Checkbox onChange={(e) => updateItemsHandler(p.id, e.target.checked)} checked={isInOrder(p)}/>
                    <img src={p.imgs[0]} alt=""/>
                    <p className="item-name">{p.name}</p>
                    <p className="item-price">{p.price} ₽</p>
                    {isInOrder(p) && <div className="item-quantity">
                        <input className="item-input" type="number" onChange={(e) => updateQuantityHandler(p.id, e.target.value)} value={getQuantity(p)}/>
                        <div className="item-buttons">
                            <button className="item-button" onClick={() => updateQuantityHandler(p.id, getQuantity(p) + 1)}>+</button>
                            <button className="item-button" onClick={() => updateQuantityHandler(p.id, getQuantity(p) - 1)}>-</button>
                        </div>
                    </div>
                    }
                </li>)
                : <Loading />}
            </ul>
            <button onClick={saveReplacementHandler} className="dialog-select-product-content-button">Сохранить замену</button>
        </div>
    </div>
  )
}

export default DialogSelectProducts