import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { DELETE_FROM_BASKET, LOAD_BASKET, MINUS_IN_BASKET, PLUS_IN_BASKET } from "../../reducers/types";
import GetProducts from "../../queries/GetProducts";
import "./basket-page.scss";
import Loading from "../fixed-elements/Loading";

const BasketPage = ({isBasketLoadToBasket,isShopsLoadToBasket}) => {
    const navigate = useNavigate();
    const basket_storage = useSelector(s => s.app.basket)
    const [isLoadData, setIsLoadData] = useState(false)
    const shops = useSelector(s => s.app.shops)
    const [data_basket, setDataBasket] = useState()
    const [resultPrice, setResultPrice] = useState()
    const dispatch = useDispatch()

    const getDataBasket = useCallback(async () => {
      if (!isLoadData && isShopsLoadToBasket && isBasketLoadToBasket) {
        console.log("Отработано")
        if(basket_storage.length && shops.length) {
          (await GetProducts(
            0,
            10000,
            0,
            basket_storage.map((el) => el.id),
            shops.find((s) => s.selected).id
          ).then((response) => {
            let local_data_basket = []
            response.result !== null &&
              response.result.data.forEach((p) => {
                local_data_basket.push({
                  id: p.id,
                  name: p.name,
                  price: p.price,
                  img: p.imgs[0],
                  amount: basket_storage.filter((b) => b.id === p.id)[0].amount,
                })
              })

            const localBasketToStorage = local_data_basket.map((el) => {
              return {
                id: el.id,
                amount: el.amount,
              }
            })
            console.log(localBasketToStorage)

            dispatch({ type: LOAD_BASKET, payload: localBasketToStorage })
            setDataBasket(local_data_basket)
            setIsLoadData(true)
          }))
        }else{
            setIsLoadData(true)
        }
      }
    }, [basket_storage, shops, dispatch, isLoadData, isShopsLoadToBasket, isBasketLoadToBasket])


    const deleteFromBasket = (id) => {
        dispatch({ type: DELETE_FROM_BASKET, payload: id })
        setDataBasket(data_basket.filter((db) => db.id !== id))
    }

    const plusInBasket = (id) => {
        dispatch({ type: PLUS_IN_BASKET, payload: id })
        setDataBasket(data_basket.map((db) => {
            if (db.id === id) {
                return { ...db, amount: db.amount + 1 }
            }
            return db
        }))
    }

    const minusInBasket = (id, amount) => {
        if (amount > 1) {
            dispatch({ type: MINUS_IN_BASKET, payload: id })
            setDataBasket(data_basket.map((db) => {
                if (db.id === id) {
                    return { ...db, amount: db.amount - 1 }
                }
                return db
            }))
        }
    }

    useEffect(() => {
        data_basket && localStorage.setItem("basket-dff-store", JSON.stringify(basket_storage))
    }, [basket_storage, data_basket])

    useEffect(() => {
        getDataBasket()
    }, [getDataBasket])

    useEffect(() => {
        data_basket &&
            setResultPrice(data_basket.reduce((acc, db) => +acc + +(db.price * db.amount), 0))
    }, [data_basket])



    return <div className="basket-container">
        <div onClick={() => navigate(-1)} className="back-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" /></svg>
            <p>Корзина</p>
        </div>
        {isLoadData
        ? basket_storage.length
                ? <div className="content-block">
                    {
                        data_basket
                        && data_basket.map(db => <div key={db.id} className="basket-item">
                            <img src={db.img} alt="" />
                            <div className="item-info">
                                <div className="item-name-delete">
                                    <p className="item-name">{db.name}</p>
                                    <div className="item-delete" onClick={() => deleteFromBasket(db.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
                                    </div>
                                </div>
                                <div className="item-control-price">
                                    <div className="item-amount-control">
                                        <svg style={{ backgroundColor: db.amount > 1 ? "#444" : "#888" }} onClick={() => minusInBasket(db.id, db.amount)} className="control-button" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M200-440v-80h560v80H200Z" /></svg>
                                        <p className="item-amount">{db.amount}</p>
                                        <svg onClick={() => plusInBasket(db.id)} className="control-button" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                                    </div>
                                    <div className="item-price"><div>{db.price}x{db.amount}= </div> <p>{db.price * db.amount} ₽</p></div>
                                </div>
                            </div>
                        </div>)

                    }
                    <div className="footer-block">
                        <div className="result-block">
                            <p className="result-block-heading">Итог</p>
                            <p className="result-block-sum">{resultPrice} ₽</p>
                        </div>
                        <NavLink to="/order" className="checkout-but">К оформлению</NavLink>
                    </div>
                </div>
                : <div className="empty-basket">
                    <p className="empty-basket-text">В корзине ничего нет</p>
                    <NavLink to="/" className="empty-basket-button">В каталог</NavLink>
                </div>
        : <Loading />
        }
    </div>
}

export default BasketPage