import { devInitData, devMode } from "../dev-mode"
import { _global_domain } from "../domain-address"

const GetProducts = async (ctgs, limit, offset, ids, shop_id) => {
  const initData = window.Telegram.WebApp.initData
  return await fetch(_global_domain + "/get_products", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Telegram-Init-Data": devMode ? devInitData : initData,
    },
    body: JSON.stringify({ ctgs, limit, offset, ids, shop_id }),
  }).then((response) => response.json())
}

export default GetProducts
