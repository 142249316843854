import { useDispatch, useSelector } from "react-redux"
import { DELETE_CATEGORY_FROM_PRODUCT } from "../../../reducers/types"
import EditProduct from "../../../queries/EditProduct"

const CategoryItem = ({ prod, ctg_id }) => { 

    const dispatch = useDispatch()
    const categories = useSelector(s => s.app.categories)
    const current_ctg = categories.filter(c => c.id === ctg_id)[0]
    const shops = useSelector(s => s.app.shops)

    const deleteCategoryFromProduct = () => {

        let new_ctgs = prod.ctgs.filter(el => el !== ctg_id)

        EditProduct("ctgs", prod.id, new_ctgs, shops.find(s => s.selected).id).then(response => response &&
            dispatch({ type: DELETE_CATEGORY_FROM_PRODUCT, payload: { prod_id: prod.id, ctg_id } })
        )
    }

    return <div className="category-item">
        <p>{current_ctg ? current_ctg.name : "[Категории не существует]"}</p>
        <svg onClick={() => deleteCategoryFromProduct()} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path fill="#444" d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
    </div>
}
export default CategoryItem