import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps"
import { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import GetContacts from "../../queries/GetContacts"
import { useSelector } from "react-redux"
import Loading from "../fixed-elements/Loading"

const Contacts = () => {
  const shops = useSelector((s) => s.app.shops)
  const [address, setAddress] = useState("")
  const [number, setNumber] = useState()
  const [telegram_link, setTelegramLink] = useState()
  const [coord, setCoord] = useState()

  const getTgLink = useMemo(() => {
    if (!telegram_link) return ''
    if (telegram_link.startsWith('http')) return telegram_link
    if (telegram_link.startsWith('@')) return `https://t.me/${telegram_link.slice(1)}`
    return `https://t.me/${telegram_link}`
  }, [telegram_link])

  useEffect(() => {
    if (shops.length > 0) {
      GetContacts(0, shops.find((s) => s.selected).id).then((response) => {
        if (response.result) {
          console.log(response.result)
          const { adress, contact_phone, telegram } = response.result.data
          setAddress(adress)
          setNumber(contact_phone)
          setTelegramLink(telegram)
          setCoord([59.937341, 30.35481])
        }
      })
    }
  }, [shops])

  return (
    !coord
    ? <Loading />
    : <div className="contacts-container">
      <h1>Контакты</h1>
      <YMaps>
        <Map width="100%" height={400} defaultState={{ center: coord, zoom: 14 }}>
          <Placemark
            modules={["geoObject.addon.balloon"]}
            geometry={coord}
            properties={{
              balloonContentBody: `<h1>Наш магазин</h1><h3>${address}</h3>`,
            }}
          />
        </Map>
      </YMaps>
      <div className="contact-content">
        <div className="contact-content__wrapper">
          <b>Адрес:</b> {address}
        </div>
        <div className="contact-content__wrapper">
          <b>Контактный номер:</b> {number}
        </div>
        <div className="contact-content__wrapper">
          <Link className="tg-link" to={getTgLink}>
            <svg xmlns="http://www.w3.org/2000/svg" height="26" width="32">
              <path fill="#444" d="M28.3170344,0.144500809 L0.631034427,11.1265008 C-0.245965573,11.4745008 -0.196965573,12.7325008 0.704034427,13.0115008 L7.68203443,15.1725008 L10.5120344,23.9295008 C10.7370344,24.6255008 11.6080344,24.8525008 12.1440344,24.3555008 L16.2450344,20.5475008 L24.0220344,26.2725008 C24.6010344,26.6985008 25.4260344,26.3935008 25.5890344,25.6945008 L31.0030344,2.45750081 C31.3690344,0.884500809 29.8180344,-0.450499191 28.3170344,0.144500809 Z M12.1080344,16.9935008 L11.2450344,22.1725008 L9.24503443,14.9225008 L28.7860344,2.17250081 L12.1080344,16.9935008 Z" id="sc-telegram"></path>
            </svg>
            <p>Telegram</p>
          </Link>
        </div>
        <p>ИП Малешева Александра Викторовна</p>
        <p>ОГРНИП 324784700344185</p>
      </div>
    </div>
  )
}

export default Contacts