import { TextField } from "@mui/material"
import { useState } from "react"
import AddNewShop from "../../queries/AddNewShop"
import "./window-screens.scss"
import AddContact from "../../queries/AddContact"
import { ADD_SHOPS } from "../../reducers/types"
import { useDispatch } from "react-redux"

const WindowScreenAddNewShop = ({setOpenAddNewShop, setIsShopsLoad}) => {

    const dispatch = useDispatch()
    const [shopName, setShopName] = useState("")
    const [shopAddress, setShopAddress] = useState("")
    const [shopNumber, setShopNumber] = useState("")
    const [shopTelegram, setShopTelegram] = useState("")
    

    function addNewShopHandler(){
        AddNewShop(shopName).then((response) => {
            console.log(response)
            AddContact(shopAddress, shopNumber, shopTelegram, response.result.shop_id).then(() => {
                setOpenAddNewShop(false)
                setIsShopsLoad(false)
                dispatch({ type: ADD_SHOPS, payload: { 
                    id: response.result.shop_id, 
                    name: shopName, 
                    selected: false, 
                    show: true
                } })
            })
        })
    }

    return <div onClick={() => setOpenAddNewShop(false)} className="add-new-shop-container">
        <div onClick={(e) => e.stopPropagation()} className="add-new-shop-block">
            <p className="add-new-shop-header">Новый магазин</p>
            <TextField className="add-new-shop-input input-type" label="Название магазина" onChange={(e) => setShopName(e.target.value)} value={shopName} fullWidth />
            <TextField className="add-new-shop-input input-type" label="Номер телефона" onChange={(e) => setShopNumber(e.target.value)} value={shopNumber} fullWidth />
            <TextField className="add-new-shop-input input-type" label="Адрес" onChange={(e) => setShopAddress(e.target.value)} value={shopAddress} fullWidth />
            <TextField className="add-new-shop-input input-type" label="Telegram" onChange={(e) => setShopTelegram(e.target.value)} value={shopTelegram} fullWidth />
            <div onClick={addNewShopHandler} className="add-new-shop-button">Добавить</div>
        </div>
    </div>
}

export default WindowScreenAddNewShop