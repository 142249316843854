import { useDispatch } from "react-redux"
import { TARGET_WS_INFO_POINTS_TARGET } from "../../reducers/types"
import "./window-screens.scss"

const WindowScreenInfoPoints = () => {

    const dispatch = useDispatch()

    return <div className="window-screen-info-points">
        <div className="window-screen-block">
            <div className="header-block">
                <h2>Оплачивайте баллами до 30% стоимости</h2>
                <div className="info-button" onClick={() => dispatch({type: TARGET_WS_INFO_POINTS_TARGET})}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#444"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg></div>
                </div>
            <p>Баллы можно потратить на бесплатные услуги</p>
        </div>
    </div>
}

export default WindowScreenInfoPoints