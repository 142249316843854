import { useNavigate } from "react-router-dom"
import "./admins-page.scss"
import Loading from "../../fixed-elements/Loading"
import { useEffect, useState } from "react"
import GetAdmins from "../../../queries/GetAdmins"
import { useDispatch, useSelector } from "react-redux"
import DeleteAdmin from "../../../queries/DeleteAdmin"
import { DELETE_ADMIN, SET_ADMINS } from "../../../reducers/types"

function AdminsPage({setOpenAddAdmin}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const adminList = useSelector(s => s.app.adminList)
  const shops = useSelector(s => s.app.shops)

  const [isAdminsLoad, setIsAdminsLoad] = useState(false)

  function deleteAdminHundler(id){
    const currentShop = shops.find(s => s.selected === true)
    DeleteAdmin(id, currentShop.id).then((response) => {
        if(!response.erorr){
            dispatch({ type: DELETE_ADMIN, payload: id })
        }
    })
  }

  useEffect(() => {
    const currentShop = shops.find(s => s.selected === true)
    if(currentShop){
        GetAdmins(currentShop.id).then((response) => {
          console.log(response.result)
            if(response.result){
                dispatch({ type: SET_ADMINS, payload: response.result.data })
              }
              setIsAdminsLoad(true)
        })
    }
  }, [shops, dispatch])

  return (
    <div className="admins-page-container admin-block-container">
      <div onClick={() => navigate(-1)} className="back-block">
        <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30">
          <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
        </svg>
        <p>Назад</p>
      </div>
      <div className="admins-page-content">
        <div className="admin-heading">
            <h1>Администраторы </h1>
            {isAdminsLoad && <button onClick={() => setOpenAddAdmin(true)} className="admin-add-button">
                <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#ffffff"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
            </button>}
        </div>
        {isAdminsLoad 
        ?<div className="admins-list">
            {adminList && adminList.map(adm => <div key={adm.id} className="admins-item">
                <div className="admin-name-id">
                    <p className="admin-id">#{adm.id_admin}</p>
                    <p className="admin-name">{adm.name}</p>
                </div>
                <div className="admin-shop">
                    <h5>Магазин</h5>
                    <p>{shops.find(s => s.id === adm.shop_id).name}</p>
                </div>
                <svg onClick={() => deleteAdminHundler(adm.id)} className="admin-delete" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path fill="#fff" d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
            </div>)}
        </div>
        : <Loading />}
      </div>
    </div>
  )
}

export default AdminsPage
