import { devInitData, devMode } from "../dev-mode"
import { _global_domain } from "../domain-address"

const EditContacts = async (adress, contact_phone, telegram, shop_id) => {
  const initData = window.Telegram.WebApp.initData
  return await fetch(_global_domain + "/edit_contacts", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Telegram-Init-Data": devMode ? devInitData : initData,
    },
    body: JSON.stringify({
      fields: { adress, contact_phone, telegram },
      shop_id
    }),
  }).then((response) => response.json())
}
export default EditContacts
