import { useCallback, useEffect, useState } from "react"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import GetOrders from "../../../queries/GetOrders"
import Loading from "../../fixed-elements/Loading"
import CheckAdmin from "../../../queries/CheckAdmin"
import { devMode, devUserId } from "../../../dev-mode"
import { useDispatch, useSelector } from "react-redux"
import GetEdaOrders from "../../../queries/GetEdaOrder"
import OrderCard from "./card/OrderCard"
import OrderYandexCard from "./card/OrderYandexCard"
import dayjs from "dayjs"
import GetTotalOrders from "../../../queries/GetTotalOrders"
import DialogSelectProducts from "./dialog-select-products/DialogSelectProducts"
import { CLOSE_SELECT_PRODUCTS } from "../../../reducers/types"

const OrdersPage = () => {
    const maxOrders = 10
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const shops = useSelector(state => state.app.shops)
    const selectProductsDialogData = useSelector(state => state.app.selectProductsDialogData)
    const isUpdateOrders = useSelector(state => state.app.isUpdateOrders)
    const [isCheckedTelegram, setCheckedTelegram] = useState(true)
    const [totalOrders, setTotalOrders] = useState(0)
    const [orders, setOrders] = useState([])
    const [isUserAdmin, setIsUserAdmin] = useState(false)
    const [ordersLoaded, setOrdersLoaded] = useState(false)

    function getDate (order) {
        const date = new Date(order.created_at || order.date_create)
        return dayjs(date)
    }
    function getCompleted (order) {
        if (order.completed) {
            return order.completed
        } else {
            return order.status === "DELIVERED" || order.status === "CANCELLED"
        }
    }
    function switchOrderList () {
        setCheckedTelegram(prev => !prev)
        navigate(`/adm7730s/orders`)
    }

    const getOrdersHundler = useCallback(async () => {
        setOrdersLoaded(true)
        const page = params.id || 1
        let _totalOrders = 0
        let response = null
        let resTotal = null
        if(isCheckedTelegram) resTotal = await GetTotalOrders(shops.find(s => s.selected).id, true)
        else resTotal = await GetTotalOrders(shops.find(s => s.selected).id, false)

        _totalOrders = resTotal.result.total_orders
        setTotalOrders(_totalOrders)

        if(isCheckedTelegram) response = await GetOrders(shops.find(s => s.selected).id, 10, (page-1)*maxOrders)
        else response = await GetEdaOrders(shops.find(s => s.selected).id, 10, (page-1)*maxOrders)
        
        console.log(response)
        setOrders([...response.result.data.map((o) => {
            return {
                ...o,
                platform: isCheckedTelegram ? "telegram" : "yandex",
            }
        })])
        setOrdersLoaded(false)
    }, [shops, isCheckedTelegram, params])

    useEffect(() => {        
        let tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe.user || devMode) {
            const propId = devMode ? devUserId : tg.initDataUnsafe.user.id
            CheckAdmin(propId).then(response => {
                setIsUserAdmin(response.result)
                !response.result && navigate("/")
            })
        }

        setOrdersLoaded(false)
        if(shops.length > 0){
            getOrdersHundler()
        }
    }, [navigate, shops, getOrdersHundler])

    useEffect(() => {
        console.log(isUpdateOrders)
        if(isUpdateOrders){
            getOrdersHundler()
            dispatch({type: CLOSE_SELECT_PRODUCTS, payload: false})
        }
    }, [isUpdateOrders, getOrdersHundler, dispatch])


    return isUserAdmin ? <div className="orders-page-container admin-block-container">
        <div onClick={() => navigate(-1)} className="back-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" /></svg>
            <p>Назад</p>
        </div>
        <div className="order-content">
            <h1>Заказы</h1>
            <div className="order-switch-platform">
                <div className="order-switch-platform-item">
                    <button 
                        onClick={switchOrderList}
                        className={`order-switch-platform-item-button ${isCheckedTelegram?"active":""}`} 
                    >Telegram</button>
                </div>
                <div className="order-switch-platform-item">
                    <button 
                        onClick={switchOrderList}
                        className={`order-switch-platform-item-button ${!isCheckedTelegram?"active":""}`}
                    >Яндекс</button>
                </div>
            </div>
            <div className="orders-pagination">
                {Number(totalOrders/maxOrders).toFixed(0) > 1 &&
                Array.from({ length: Number(totalOrders/maxOrders).toFixed(0) }).map((_, i) => 
                    <NavLink style={{opacity: (+i === ((+params.id - 1) || 0)) ? ".5" : "1"}} key={i} className="orders-pagination-item" to={`/adm7730s/orders/${i+1}`}>{i+1}</NavLink>
                )}
            </div>
            {orders.length
            ? orders.sort((a, b) => getDate(b) - getDate(a))
            .sort((a, b) => getCompleted(a) - getCompleted(b)).map((o, i) => {
            return o.platform === "telegram" 
            ? <OrderCard key={i} order={o} orders={orders} setOrders={setOrders} />
            : <OrderYandexCard key={i} order={o} orders={orders} setOrders={setOrders} />
        })
            : ordersLoaded 
                ? <h3>Нет заказов</h3> 
                : <Loading />
            }
        {selectProductsDialogData !== null && <DialogSelectProducts />}
        </div>
    </div>
    : <Loading />
}

export default OrdersPage