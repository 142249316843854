import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, TextField } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import "./window-screens.scss"
import { useDispatch, useSelector } from "react-redux"
import AddAdmin from "../../queries/AddAdmin"
import { ADD_ADMIN } from "../../reducers/types"

const WindowScreenAddAdmin = ({ setOpenAddAdmin }) => {
  const [open, setOpen] = useState(false)
  const shops = useSelector(s => s.app.shops)
  const dispatch = useDispatch()

  const [fieldAdminId, setFieldAdminId] = useState("")
  const [fieldAdminName, setFieldAdminName] = useState("")
  const [selectShop, setSelectShop] = useState(null)

  const anchorRef = useRef(null)
  const prevOpen = useRef(open)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  function addAdminHandler() {
    AddAdmin(fieldAdminId, fieldAdminName, selectShop.id).then((response) => {
        console.log(response)
        dispatch({ type: ADD_ADMIN, payload: {
            id: response.result.id,
            name: fieldAdminName,
            id_admin: fieldAdminId,
            shop_id: selectShop.id
        } })
    })
    setOpenAddAdmin(false)
  }
  
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div onClick={() => setOpenAddAdmin(false)} className="add-new-shop-container">
      <div onClick={(e) => e.stopPropagation()} className="add-new-shop-block add-admin-block">
        <p className="add-new-shop-header">Администратор</p>
        <TextField className="add-new-shop-input input-type" label="ID пользователя" onChange={(e) => setFieldAdminId(e.target.value)} value={fieldAdminId} fullWidth />
        <TextField className="add-new-shop-input input-type" label="Имя" onChange={(e) => setFieldAdminName(e.target.value)} value={fieldAdminName} fullWidth />
        <Button ref={anchorRef} className="select-shop-button" aria-controls={open ? "composition-menu" : undefined} aria-expanded={open ? "true" : undefined} aria-haspopup="true" onClick={handleToggle}>
            {selectShop ? selectShop.name : "Выбрать магазин"}
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                    {shops.map(s => <MenuItem key={s.id} onClick={(e) => {setSelectShop(s); handleClose(e)}}>{s.name}</MenuItem>)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <div onClick={addAdminHandler} className="add-new-shop-button">
          Добавить
        </div>
      </div>
    </div>
  )
}

export default WindowScreenAddAdmin
